import { Security } from "@okta/okta-react";
import { AlertProvider, BCR, Blue } from "best-common-react";
import React from "react";
import HttpInterceptor from "../api/HttpInterceptor";
import { GlobalProvider } from "../contexts/GlobalContext";
import { PermissionsProvider } from "../contexts/PermissionsContext";
import { oktaAuth, UserProvider } from "../contexts/UserContext";

const WrapperProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string): Promise<void> => {
    window.location.replace(originalUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <UserProvider>
        <PermissionsProvider>
          <AlertProvider>
            <HttpInterceptor>
              <BCR theme={Blue}>
                <GlobalProvider>{children}</GlobalProvider>
              </BCR>
            </HttpInterceptor>
          </AlertProvider>
        </PermissionsProvider>
      </UserProvider>
    </Security>
  );
};

export default WrapperProvider;
